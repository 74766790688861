import { RestService } from "@/app/core/services/core/RestService";
import { Location } from "@/app/core/models/domain/Location";
import { State } from "@/app/core/models/domain/State";
import { Area } from "@/app/core/models/domain/Area";
import { AreaType } from "@/app/core/enums/AreaType";

export class LocationService extends RestService<Location> {
  constructor() {
    super("/locations", "locations", "");
  }

  async getLocations(search: string): Promise<Location[]> {
    return await this.httpGetAll(`${this.apiHref()}/${search}`);
  }

  async getStates(): Promise<State[]> {
    return await this.httpGetAll(`${this.apiHref()}/states`);
  }

  async getAreas(type: AreaType): Promise<Area[]> {
    return await this.httpGetAll(`${this.apiHref()}/areas?type=${type}`);
  }

  async getLocationByCoordinates(latitude: number, longitude: number): Promise<Location> {
    const coordinates = {
      latitude: latitude,
      longitude: longitude,
    };
    return await this.httpPost(`${this.apiHref()}/coordinates`, coordinates);
  }
}
